<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
  },
  data() {
    return {
      informative: null,
    };
  },
  methods: {
    getInformative() {
      api
        .get('informatives/' + this.$route.params.id)
        .then((response) => {
          if (response.data.status == 'success') {
            this.informative = response.data.informative
          }
        })
    }
  },
  mounted() {
    this.getInformative()
  }
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item font-size-16"><router-link tag="a" to="/informatives">Informativos</router-link></li>
      <li v-if="informative && informative.title" class="breadcrumb-item font-size-16 d-none d-sm-block">{{ informative.title }}</li>
    </ol>

    <div v-if="informative && informative.title" class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ informative.title }}</h4>
        </div>
      </div>
    </div>

    <div v-if="!informative" class="text-center">
      <b-spinner class="align-middle" variant="default" role="status"></b-spinner>
    </div>
    <div v-else class="row justify-content-center">
      <div class="col-md-10 col-lg-6">
        <div class="card">
          <div class="card-body">
            <img v-if="informative.cover" class="informative-cover" :src="informative.cover" />
            <div class="d-flex align-items-center justify-content-between mb-4">
              <div class="d-flex align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
                  <path d="M7 11h2v2H7zm0 4h2v2H7zm4-4h2v2h-2zm0 4h2v2h-2zm4-4h2v2h-2zm0 4h2v2h-2z"></path>
                  <path d="M5 22h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zM19 8l.001 12H5V8h14z"></path>
                </svg>
                <div class="ml-1 text-dark font-weight-bold">{{ informative.date.start }}</div>
              </div>
            </div>
            <div v-html="informative.message"></div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.informative-cover {
  border-radius: 5px;
  margin-bottom: 20px;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 10 / 5;
}
.informative-youtube {
  margin-bottom: 20px;
  width: 100%;
  aspect-ratio: 16 / 9;
}
</style>
